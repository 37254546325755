import Main from './components/Origin/main';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBDWrhvT8TXnn2lE7HnArRjlC9VqPBqkg",
  authDomain: "dikshitpatoliya-57f60.firebaseapp.com",
  projectId: "dikshitpatoliya-57f60",
  storageBucket: "dikshitpatoliya-57f60.appspot.com",
  messagingSenderId: "625002537752",
  appId: "1:625002537752:web:9541c5f098e8d49edef46d",
  measurementId: "G-W2BSV24DN4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
    return <Main/>;
}

export default App;