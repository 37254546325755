import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import 'swiper/swiper-bundle.css';

/*--------------------
* Testimonial Section
----------------------*/
export default function Testimonial() {
    const testimonail_list = [
        {id:1, 'image':'img/Client/avatar-1.jpg', author_name:'Neha', position:'India', description: "Dikshit is one of the best React Native developer I have hired on Upwork. If you have the opportunity to hire him do it, you will not regret it. Excellent technical skills, creative mindset, and great ability to understand requirements and implement solutions. I will definitely hire again."},
        {id:2, 'image':'img/Client/avatar-2.jpg', author_name:'David I', position:'Spain', description: "Patoliya is one of the best freelancers in upwork. I challenged him with a demanding and difficult task and he was able to provide a working solution within minutes. Really appreciate and love this freelancer's job. I couldn't be happier. Working towards working with him again. If I could, I would give him more than 5 stars. Really outstanding and superb work"},
        {id:3, 'image':'img/Client/avatar-3.jpg', author_name:'copata', position:'Nigeria', description: "Very patient and skilled developer."},
        {id:3, 'image':'img/Client/avatar-4.png', author_name:'Fredrik Pihlgren', position:'Norrkoping, Sweden', description: "He solved the problem for me. Recommended guy to work with!"},
        {id:3, 'image':'img/Client/avatar-1.jpg', author_name:'Kishan Arava', position:'Australia', description: "Works very quickly. Enjoy working with him. Will hire again."},

    ]

    return (
        <>
        <section className="section testimonial-section">
            <div className="container">
                <div className="grid section-heading">
                    <div className="lg:col-span-6 text-center mx-auto">
                        <h3><span>Testimonial</span></h3>
                    </div>
                </div>
                <Swiper 
                    spaceBetween={30} 
                    slidesPerView={2} 
                    autoplay
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                    breakpoints={{
                        0: {
                        slidesPerView: 1,
                        },
                        992:{
                        slidesPerView:2,
                        },
                    }}
                >
                    {
                        testimonail_list.map((val ,i) =>{
                            return <SwiperSlide key={i}>
                                        <div className="feature-box-03 h-72">
                                            <div className="feature-img rounded-full">
                                                <img src={val.image}  title={val.image} alt={val.image}/>
                                            </div>
                                            <div className="feature-content">
                                                <div className="icons">
                                                    <i className="fas fa-quote-left"></i>
                                                </div>
                                                <h6>{ val.author_name }</h6>
                                                <span>{ val.position }</span>
                                                <p>{ val.description }</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })
                    }
                </Swiper>
            </div>
        </section>
        </>
    );
}
