import { useState } from "react";
import { Link } from "react-scroll";

/*--------------------
* Proejct Section
----------------------*/

function Proejct(props) {
    return (
        <>
            <div className="px-modal mfp-hide">
                <div className="single-project-box">
                    <div className="grid grid-cols-12 gx-3">
                        <div className="col-span-12 md:col-span-7 mb-10 md:mb-0">
                            <div className="grid grid-cols-2 gap-2">
                                {props.projectDetails.images.map((img, i) => {
                                    return (
                                        <div key={`image_${i}`} className="col-span-1">
                                            <img className="border" src={img} title="" alt="react native" />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-5 md:pl-10 lg:pl-14">
                            <h4 className="font-[600] text-[25px] text-black mb-4 pb-4 border-b">{props.projectDetails.title}</h4>
                            <p className="mb-3">{props.projectDetails.description}</p>
                            <p>{props.projectDetails.subDescription}</p>
                            <ul className="m-0 p-0 pt-7 list-none">
                                {props.projectDetails.details.map((detail, i) => {
                                    return (
                                        <li key={`detail_${i}`} className="flex py-2">
                                            <span className="w-[100px] font-[600] text-black">{detail.title}:</span>
                                            <span>{detail.description}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <button className="px-close" onClick={props.closePopup}><i className="fa fa-times"></i></button>
                    </div>
                </div>
            </div>
        </>
    );
}


/*--------------------
* Work Section
----------------------*/

export default function Work() {
    const [showModal, setShowModal] = useState(0);
    const closePopup = () => {
        setShowModal(0);
    }

    const projectDetails = [
        {
            title: 'Budget Control - Money Tracker & Expense Manager App',
            description: 'We are seeking a skilled React Native developer to create a budget control app for our financial management platform. The app will provide users with the ability to track their expenses, set budgets, and receive notifications to maintain better control over their finances. This project aims to help individuals and families manage their spending habits effectively and achieve their financial goals.',
            subDescription: 'esigning a user-friendly and intuitive interface is crucial to ensure that users can easily navigate and utilize the features of the app. Additionally, incorporating educational content on financial literacy within the app can enhance the overall user experience and help users make informed financial decisions.',
            images: ['img/BudgetControlApp/img-1.png', 'img/BudgetControlApp/img-2.png', 'img/BudgetControlApp/img-3.png', 'img/BudgetControlApp/img-4.png'],
            details: [
                {
                    title: 'Type',
                    description: 'App',
                },
                {
                    title: 'Langages',
                    description: 'React Native, Node Js',
                },
                {
                    title: 'Platform',
                    description: 'Android & IOS',
                },
                // {
                //     title: 'Country',
                //     description: 'India',
                // },
                // {
                //     title: 'Live URL',
                //     description: 'www.example.com',
                // },
            ]
        },
        {
            title: 'Bammby - Real people, true matches.',
            description: `Looking for your perfect match? Bammby is here to help! Our AI-powered app matches you with individuals based on compatibility, making it easier than ever to find love. Start swiping and discover your soulmate today! Get ready for a dating experience like no other! With Bammby, you'll meet amazing people who truly click with you. Don't wait, start your journey to love now!.`,
            subDescription: 'Looking for love? Try Bammby! Our AI matches you based on compatibility. Find your perfect match! #BammbyLove',
            images: ['img/Bammby/img-1.png', 'img/Bammby/img-2.png', 'img/Bammby/img-3.png', 'img/Bammby/img-4.png'],
            details: [
                {
                    title: 'Type',
                    description: 'App',
                },
                {
                    title: 'Langages',
                    description: 'React Native',
                },
                {
                    title: 'Platform',
                    description: 'Android & IOS',
                },
                // {
                //     title: 'Country',
                //     description: 'USA',
                // },
                // {
                //     title: 'Live URL',
                //     description: 'www.example.com',
                // },
            ]
        },
        {
            title: 'Wallwrap - HD, 4K Wallpaper with 20+ categories',
            description: 'We at Wallwrap think that a creative add-on to a person’s life can make a major change and Wallpaper are the Best Creative addition to everyone life. We want our app to showcase the artists and our own best creative qualities.',
            subDescription: '',
            images: ['img/Wallwrap/img-1.png', 'img/Wallwrap/img-2.png', 'img/Wallwrap/img-3.png', 'img/Wallwrap/img-4.png'],
            details: [
                {
                    title: 'Type',
                    description: 'App',
                },
                {
                    title: 'Langages',
                    description: 'React Native',
                },
                {
                    title: 'Platform',
                    description: 'Android & IOS',
                },
                // {
                //     title: 'Country',
                //     description: 'USA',
                // },
                // {
                //     title: 'Live URL',
                //     description: 'www.example.com',
                // },
            ]
        },
        {
            title: 'Cupid - Dating App',
            description: 'Cupid is a free dating app founded on respect, equality, and inclusion. You can find dates, make friends, and meet new people the way you always done — but on Cupid, women always make the first move.',
            subDescription: '',
            images: ['img/Cupid/img-1.png', 'img/Cupid/img-2.png'],
            details: [
                {
                    title: 'Type',
                    description: 'App',
                },
                {
                    title: 'Langages',
                    description: 'React Native',
                },
                {
                    title: 'Platform',
                    description: 'Android & IOS',
                },
                // {
                //     title: 'Country',
                //     description: 'USA',
                // },
                // {
                //     title: 'Live URL',
                //     description: 'www.example.com',
                // },
            ]
        },
    ]
    return (
        <>
            <section data-scroll-data="3" id="portfolio" className="section bg-orange-50">
                <div className="container">
                    <div className="grid section-heading">
                        <div className="lg:col-span-6 text-center mx-auto">
                            <h3><span>Latest Projects</span></h3>
                        </div>
                    </div>
                    <div className="lightbox-gallery portfolio-box">`
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/BudgetControlApp/img-1.png" title="app" alt="reactnative" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>Android & IOS</span></h6>
                                    <h4>Budget Control - Money Tracker & Expense Manager App</h4>
                                    <p>The app will provide users with the ability to track their expenses, set budgets, and receive notifications to maintain better control over their finances. This project aims to help individuals and families manage their spending habits effectively and achieve their financial goals.</p>
                                    {/* <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(1)}>View Project </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/Bammby/img-1.png" title="Bammby" alt="Bammby" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>Android & IOS</span></h6>
                                    <h4>Bammby - Real people, true matches.</h4>
                                    <p>Looking for love? Try Bammby! Our AI matches you based on compatibility. Find your perfect match! #BammbyLove</p>
                                    {/* <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(2)}>View Project </div>
                                    </div> */}
                                    <div className="flex space-x-4 mt-3">
                                        <a href=" https://apps.apple.com/in/app/bammby/id6450325156" target="_blank">
                                            <img src={"img/appStore.png"} className="w-32" title="" alt="react native" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.bammby.app" target="_blank">
                                        <img src={"img/google.png"} className="w-32" title="" alt="react native" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/‎Billiant/img-1.png" title="app" alt="Billiant" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>IOS</span></h6>
                                    <h4>Billiant: AI Bill Tracker</h4>
                                    <p className="mb-4">Automatically and securely, bills are detected straight from your email inbox. Smart categorisation keeps them organised while reminder notifications ensure that late fees become a thing of the past. Accessible on the go, you can search past bills and easily keep track of spending over time. Electricity, gas, internet, rego, council rates, you name it, Billiant has you covered.</p>
                                    {/* <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(3)}>View Project </div>
                                    </div> */}
                                      <a href="https://apps.apple.com/us/app/billiant-ai-bill-tracker/id1457021397?ls=1" target="_blank">
                                            <img src={"img/appStore.png"} className="w-32" title="" alt="react native" />
                                        </a>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gx-3 mb-5 pb-lg-3 mb-lg-3 pb-12 portfolio-box justify-around items-center">
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10 md:order-last mb-10 md:mb-0">
                                <div className="portfolio-img">
                                    <img src="img/Dating/img-1.png" title="" alt="reactnative" />
                                    <Link to="#" className="gallery-link gallery-link-icon">
                                        <i className="fas fa-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-6 md:px-5 lg:px-10">
                                <div className="portfolio-text">
                                    <h6><span>Android & IOS</span></h6>
                                    <h4>One% Dating Club</h4>
                                    <p>Welcome to One% Dating Club, the exclusive, free dating platform tailored for ambitious professionals seeking meaningful connections. Unlike conventional dating apps, we prioritize depth, matching you with individuals who not only meet your preferences but also share your aspirations and success. It’s where romance meets ambition, in an environment designed for those who want more out of their relationships — all without a subscription fee.</p>
                                    {/* <div className="btn-bar">
                                        <div className="px-btn px-btn-theme px_modal" onClick={() => setShowModal(4)}>View Project </div>
                                    </div> */}
                                      <div className="flex space-x-4 mt-3">
                                        <a href="https://apps.apple.com/in/app/one-dating-club/id6474623186" target="_blank">
                                            <img src={"img/appStore.png"} className="w-32" title="" alt="react native" />
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=com.onepercent.datingclub" target="_blank">
                                        <img src={"img/google.png"} className="w-32" title="" alt="react native" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {showModal ? (
                <>
                    <Proejct closePopup={closePopup} projectDetails={projectDetails[showModal - 1]}></Proejct>
                </>
            ) : null}
        </>
    );
}
