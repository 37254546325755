
/*--------------------
* Contact Section
----------------------*/

import { collection, addDoc } from "firebase/firestore";
import { useState, } from "react";
import { db } from "../../config/firebase";

export default function Contact() {
    const [FirstName, setFirstName] = useState('');
    const [Email, setEmail] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');

    const ClientCollectionRef = collection(db, "Client");

    const sendMessage = async () => {
        if(FirstName && Email && Message){
        try {
            await addDoc(ClientCollectionRef, {
                Email: Email,
                FirstName: FirstName,
                Subject: Subject,
                Message: Message
            })
            alert('Thanks for choosing us!')
            setFirstName('');
            setEmail('');
            setSubject('');
            setMessage('');
        } catch (e) {
            console.log('e', e)
        }
    }else{
        alert('Please Enter Your Deatils')
    }
    }

    return (
        <>
            <section data-scroll-data="4" id="contactus" className="section contactus-section bg-slate-900" style={{ "backgroundImage": "url(img/effect/bg-effect-3.png)", "backgroundRepeat": "no-repeat", "backgroundSize": "cover", "backgroundPosition": "center" }}>
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12 lg:col-span-6 ">
                            <div className="p-[25px] md:p-[35px] bg-white">
                                <h6 className="text-[32px] font-[600] text-black mb-[5px]">Get in touch</h6>
                                <p className="text-[18px] mb-[30px]">Our friendly team would love to hear from you.</p>
                                <form id="contact-form" method="POST">
                                    <div className="grid grid-cols-12 gap-3">
                                        <div className="col-span-12 md:col-span-6">
                                            <div className="form-group">
                                                <label className="form-label">First name</label>
                                                <input name="Name" id="name" placeholder="Name *" className="form-control" type="text"
                                                value={FirstName}
                                                    onChange={(e) => setFirstName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-span-12 md:col-span-6">
                                            <div className="form-group">
                                                <label className="form-label">Your Email</label>
                                                <input name="Email" id="email" placeholder="Email *" className="form-control" type="email"
                                                value={Email}
                                                    onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="form-group">
                                                <label className="form-label">Subject</label>
                                                <input name="Subject" id="subject" placeholder="Subject *" className="form-control" type="text"
                                                 value={Subject}
                                                    onChange={(e) => setSubject(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="form-group">
                                                <label className="form-label">Your message</label>
                                                <textarea name="message" id="message" placeholder="Your message *" rows="4" className="form-control"
                                                value={Message}
                                                    onChange={(e) => setMessage(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-span-12">
                                            <div className="send">
                                                <button className="px-btn px-btn-theme2" type="button" value="Send"

                                                    onClick={() => sendMessage()}> Send Message</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6 flex">
                            <div className="lg:max-w-[410px] w-full lg:ml-auto pt-[50px] lg:pt-0">
                                <div className="pb-10">
                                    <img className="w-full" src="img/contact-bg.png" title="" alt="" />
                                </div>
                                <ul className="contact-infos">
                                    <li>
                                        <div className="icon bg-1">
                                            <i className="fas fa-envelope"></i>
                                        </div>
                                        <div className="col">
                                            <h5>Phone</h5>
                                            <p>+91 9879439697</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon bg-2">
                                            <i className="fas fa-phone-alt"></i>
                                        </div>
                                        <div className="col">
                                            <h5>Mail</h5>
                                            <p>patoliyadikshit17@gmail.com</p>
                                        </div>
                                    </li>
                                    {/* <li>
                                    <div className="icon bg-3">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div className="col">
                                        <h5>Visit My Studio</h5>
                                        <p>Warnwe Park Streetperrine, <br />FL 33157 New York City</p>
                                    </div>
                                </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
